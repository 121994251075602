import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { isProductLimitNotReached } from 'utils/contracts'
import { getFamily } from 'store/families/actions'
import ContractEdit from 'components/BenefitDetail/ContractEdit'
import './Kindergarten.scss'
import ContractPrice from "components/BenefitDetail/ContractPrice"

interface Props {
  contract: Contract
  simulation: Simulation
  user: User
  family: Family[]
  onChange: () => void
  dispatch: (action: any) => Promise<any> | void
}

class Kindergarten extends React.Component<Props & WithTranslation> {
  constructor(props) {
    super(props)
    this.props.dispatch(getFamily())
  }

  render() {
    const { family, contract, simulation, t } = this.props
    const isLimitNotReached = isProductLimitNotReached(contract, simulation)
    // display only above 3 years old
    const childrenBelow3 = family.filter(
      child => moment().diff(child.birthday, 'years') <= 2
    )

    return isLimitNotReached ? (
      <React.Fragment>
        <h4 className="mb-4">{t('products.select_children')}</h4>
        <div className="panel panel-primary">
          <div className="panel-body">
            {!childrenBelow3.length && (
              <div className="alert alert-default">
                {t('products.no_children')}
              </div>
            )}

            {childrenBelow3.map(child => (
              <div key={child.id} className="children-row">
                <div className="child-info">{child.fullName}</div>
                <ContractEdit {...this.props} familyId={child.id} />
              </div>
            ))}
          </div>
        </div>

        <div className="text-right mb-2">
          <Link to="/profile?tab=family" className="mr-3">
            <img src="/img/add-family.jpg" alt="child" width="50" />
            {t('buttons.add_child')}
          </Link>

          <ContractPrice price={contract.price} discount={contract.userBag}/>

        </div>
      </React.Fragment>
    ) : (
      <div className="alert alert-danger" role="alert">
        {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
      </div>
    )
  }
}

export default withTranslation()(Kindergarten)
