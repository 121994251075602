import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import moment from 'moment'
import { showNotify } from 'utils/notify'
import { formatCurrency } from 'utils/number'
import * as actions from 'store/cart/actions'
import OtherProductRow from './OtherProductRow'
import KindergartenRow from './KindergartenRow'
import HealthInsuranceRow from './HealthInsuranceRow'
import './CartEditStep.scss'
import { findFirstOrderMonth } from '../utils'
import access from 'safe-access'

type Props = {
  cart: Contract[]
  family: Family[]
  simulation: Simulation
  user: User
  dispatch(action: any): void
  joinProductNames(products: any): string
  onBack(): void
  onSubmit(params?): void
}

/**
 * @constructor
 */
class CartEditStep extends React.Component<Props & WithTranslation> {

  /**
   *
   * @param total
   */
  getCompanyBag = total => {
    const {
      user: { company },
    } = this.props
    if (!company.companyBag) {
      return 0
    }

    const value = parseInt(company.companyBag, 10)
    if (company.companyBag.endsWith('%')) {
      return Math.round((total * value) / 100)
    } else {
      return value
    }
  }

  /**
   *
   * @param contract
   * @param updated
   */
  handleUpdate = (contract: Contract, updated: Contract) => {
    return this.props.dispatch(
      actions.updateItem(contract.id, updated.toJSON())
    )
  }

  /**
   *
   * @param contract
   * @param additionalInfo
   */
  handleInfoUpdate = (contract: Contract, additionalInfo: any) => {
    this.props.dispatch(actions.updateItem(contract.id, { additionalInfo }))
  }

  /**
   *
   * @param contract
   */
  handleDelete = (contract: Contract) => {
    this.props.dispatch(actions.removeItem(contract.id))
  }

  /**
   *
   * @param cartItem
   */
  getTimeToExpiration = (cartItem) => {
    const { user: { company }, t } = this.props
    const { items } = cartItem

    const firstOrderMonth = findFirstOrderMonth(items)
    const limitDay = company.cutOffDate || 1
    let limitYear = cartItem.year
    let limitMonth = firstOrderMonth - 1

    if(limitMonth < 1) { //change of year (prev)
      limitMonth = 12 - Math.abs(limitMonth)
      limitYear -= 1
    }
    const limitMonthMoment = limitMonth - 1
    const lastAvailableDate = moment().year(limitYear).month(limitMonthMoment).date(limitDay)

    const diff = lastAvailableDate.diff(moment(),'days')

    if(diff < 0) {
      return null
    }

    return `${diff} ${t('cart.days')}`
  }

  /**
   *
   * @param cartItem
   */
  checkIsExpired = (cartItem) => {
    return this.getTimeToExpiration(cartItem) === null
  }

  /**
   *
   */
  handleNextStep = () => {
    const { cart, joinProductNames, user, t } = this.props
    const availableItems = []
    const anotherCollectiveProducts = cart.filter(cartItem => !(cartItem.benefit.collectiveId === user.collectiveId))
    const expiredProducts = cart.filter(cartItem => this.checkIsExpired(cartItem))

    if (anotherCollectiveProducts.length) {
      window.alert(
        `${t('cart.remove_products')}: ${joinProductNames(anotherCollectiveProducts)}. ${t('cart.no_permission')}!`
      )
      return
    }

    if (expiredProducts.length) {
      window.alert(
        `${t('cart.expired_products')} ${joinProductNames(expiredProducts)}`
      )
      return
    }

    for (let i = 0; i < cart.length; i += 1) {
      const isSurvey = access(cart[i], "benefit.product.isDocumentRequired") || access(cart[i], "benefit.product.isQuestionsRequired")

      if (isSurvey && (!cart[i].additionalInfo || !Object.keys(cart[i].additionalInfo).length)) {
        showNotify(
            'Rellena el documento para poder continuar',
            'error'
        )
        return
      }

      if (!cart[i].isMissingDocument()) {
        availableItems.push(cart[i])
      }
    }

    if (!availableItems.length) {
      showNotify(
        'Rellena el documento para poder continuar',
        'error'
      )
      return
    } else if (
      availableItems.length < cart.length &&
      !window.confirm(
        `Falta un documento par uno de los productos. Si pulsas 'Ok' seguiras con la contratación del resto de productos.`
      )
    ) {
      return
    }

    this.props.onSubmit(availableItems)
  }

  /**
   *
   * @param item
   */
  createRow = (item) => {
    const { t, user, family } = this.props
    const timeLeft = this.getTimeToExpiration(item)

    const expirationNode = (
        <p className={!timeLeft ? 'text-danger' : ''}>
          {timeLeft || t('cart.expired_warning')}
        </p>
    )

    switch(item.product) {
      case "kindergarten":
        return (
            <KindergartenRow
                key={item.id}
                cartItem={item}
                expirationNode={expirationNode}
                family={family}
                dispatch={this.props.dispatch}
                onUpdate={updated => {
                  this.handleUpdate(item, updated)
                }}
                onInfoUpdate={info => {
                  this.handleInfoUpdate(item, info)
                }}
                onDelete={() => {
                  this.handleDelete(item)
                }}
            />
        )

      case "health-insurance":
        return (
            <HealthInsuranceRow
                key={item.id}
                cartItem={item}
                expirationNode={expirationNode}
                family={family}
                user={user}
                onUpdate={updated => {
                  this.handleUpdate(item, updated)
                }}
                onInfoUpdate={info => {
                  this.handleInfoUpdate(item, info)
                }}
                onDelete={() => {
                  this.handleDelete(item)
                }}
            />
        )

      default:
        return (
            <OtherProductRow
                key={item.id}
                cartItem={item}
                user={user}
                expirationNode={expirationNode}
                onUpdate={updated => {
                  this.handleUpdate(item, updated)
                }}
                onInfoUpdate={info => {
                  this.handleInfoUpdate(item, info)
                }}
                onDelete={() => {
                  this.handleDelete(item)
                }}
            />
        )
    }
  }

  /**
   *
   */
  getCartItems = () => {
    const { cart } = this.props

    return cart.map(this.createRow)
  }

  /**
   *
   */
  getCompanyBagSpent = () => {
    const {
      simulation: {
        companyBag,
        totalPrice,
      },
    } = this.props

    if (!companyBag) return 0
    if (companyBag.endsWith('%')) return parseFloat(companyBag)

    const companyBagPrice = parseInt(companyBag, 10)

    if (companyBagPrice > 0) {
        const discount = Math.min(companyBagPrice, totalPrice)
        return Math.round((discount / companyBagPrice) * 100)
    }

    return 0
  }

  /**
   *
   */
  getTopBagSpent = () => {
    const {
      simulation: {
        topBag,
        availableTop,
      },
    } = this.props

    return Math.round(
        ((topBag - availableTop) / topBag) * 100
    )
  }

  /**
   *
   */
  getFlexBagSpent = () => {
    const {
      simulation: {
        flexibleBag,
        available,
      },
    } = this.props

    return Math.round(
        ((flexibleBag - available) / flexibleBag) * 100
    )
  }

  /**
   *
   */
  render() {
    const {
      simulation: {
        topBag,
        totalPrice,
      },
      t,
    } = this.props

    const companyBagSpent = this.getCompanyBagSpent()

    return (
      <div className="cart-detail">
        <div className="invoice">
          <div className="text-center">
            <span className="badge badge-roundless cart-title">
              {t('cart.modality_info_details')}
            </span>
          </div>
          <table className="table responsive v-middle table-cart">
            <colgroup>
              <col width="130px" />
              <col width="auto" />
              <col width="auto" />
              <col width="90px" />
              <col width="80px" />
              <col width="100px" />
            </colgroup>
            <thead>
              <tr>
                <th>{t('cart.modality')}</th>
                <th>{t('cart.beneficiary_info')}</th>
                <th className="text-center">{t('cart.additional_info')}</th>
                <th className="text-center">{t('cart.time_left')}</th>
                <th className="text-center">{t('cart.total')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.getCartItems()}
            </tbody>
          </table>

          <div className="text-right">
            <div className="total">
              {t('cart.total_spent')}: {formatCurrency(totalPrice)}
            </div>
            <div className="bag">
              %{t('cart.flexible_bag')}: {this.getFlexBagSpent()}%
            </div>
            {topBag && (
              <div className="bag">
                %{t('cart.top_bag')}: {this.getTopBagSpent()}%
              </div>
            )}
            {!!companyBagSpent && (
              <div className="bag">
                %{t('cart.company_bag')}: {companyBagSpent}%
              </div>
            )}
          </div>
        </div>

        <div className="text-center">
          <Link to="/home" className="btn-none mr-5">
            <img src="/img/back.jpg" alt="cart" width="50" />{' '}
            {t('buttons.back_simulation')}
          </Link>
          <button
            type="button"
            className="btn-none px-5"
            onClick={this.handleNextStep}
          >
            <img src="/img/cart.jpg" alt="cart" width="50" />{' '}
            {t('buttons.next_step')}
          </button>
        </div>
      </div>
    )
  }
}

export default withTranslation()(CartEditStep)
