/* eslint-disable */
import React from 'react'
import {withRouter} from 'react-router-dom'

import {removeItem} from 'store/cart/actions'
import {useTranslation} from 'react-i18next'
import './Topbar.scss'

type Props = {
  user: User
  location: {
    pathname: string
  }
  cart: Contract[]
  logout(): void
  dispatch(action: any): void
}

const isActive = (path, location) => (path === location ? 'active' : '')

const Index: React.FC<Props> = props => {
  const { user, cart, location } = props

  const { t, i18n } = useTranslation()

  const { pathname } = location

  const { company } = user
  const style: any = {}

  if (company.colorBar) {
    style.backgroundColor = company.colorBar
  }
  if (company.colorTitles) {
    style.color = company.colorTitles
  }

  const disableLanguage = process.env.REACT_APP_DISABLE_LANGUAGE === '1'

  const isHome = pathname == '/home'


  const languages = [
    { code: 'es', name: 'Español' },
    { code: 'ca', name: 'Catalan' },
    { code: 'en', name: 'English' },
    { code: 'pt', name: 'Português' },
  ]

  const supportedLanuages = languages.filter(
    item => company.languages && company.languages.indexOf(item.code) >= 0
  )

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  const handleRemoveItem = (e, item) => {
    e.preventDefault()
    props.dispatch(removeItem(item.id))
  }

  return (
    <nav className="navbar navbar-default navbar-fixed-top cb-navbar-content"  style={style}>

      <button
          type="button"
          className="logout-mobile-button"
          onClick={props.logout}
      >
                <span className="cp" title="Log out">
                   <i className="logout-mobile entypo-logout"/>
                </span>
      </button>

      {
        !disableLanguage && (
              <div className="btn-group">
                <button
                    type="button"
                    className="btn btn-primary dropdown-toggle menu-language-button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <img
                      src={`/assets/images/flags/flag-${i18n.language}.png`}
                      alt="flag"
                      width="16"
                      height="16"
                  />{' '}
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  {supportedLanuages.map(lang => (
                      <li
                          key={lang.code}
                          className={i18n.language === lang.code ? 'active' : ''}
                      >
                        <a
                            onClick={() => {
                              changeLanguage(lang.code)
                            }}
                        >
                          <img
                              src={`/assets/images/flags/flag-${lang.code}.png`}
                              alt="flag"
                              width="16"
                              height="16"
                          />
                          <span>{lang.name}</span>
                        </a>
                      </li>
                  ))}
                </ul>
              </div>
          )
      }

    </nav>
  )
}

export default withRouter(Index)
