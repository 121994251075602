import React from 'react'
import { useTranslation } from 'react-i18next'

import ServerImage from 'components/ServerImage'
import './ProvidersStep.scss'

interface Props {
  providers: Provider[]
  onSubmit(provider: Provider): void
}

const ProvidersStep = ({ providers, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="company-selection">
      {providers.map(provider => (
        <div
          key={provider.id}
          className="provider"
          onClick={() => {
            onSubmit(provider)
          }}
        >
          <div className="provider-info">
            {provider.logo ? (
              <ServerImage src={provider.logo} alt={provider.name} />
            ) : (
              provider.name
            )}
          </div>
          <button type="button" className="btn-none">
            <img src="/img/select.jpg" alt="select" width="50" />{' '}
            {t('buttons.select')}
          </button>
        </div>
      ))}
    </div>
  )
}

export default ProvidersStep
