import React, { ReactNode } from 'react'

import { formatCurrency } from 'utils/number'
import BenefitSimple from 'components/benefits/BenefitSimple'
import FamilySummary from 'components/profile/FamilySummary'
import FamilySelection from 'pages/Products/HealthInsurance/FamilySelection'
import MonthEditDialog from '../MonthEditDialog'
import AdditionalInfoColumn from '../AdditionalInfoColumn'

interface Props {
  cartItem: Contract
  family: Family[]
  onUpdate(updated: Contract): void
  onInfoUpdate(info: any): void
  onDelete(): void
  user: User
  expirationNode: ReactNode
}

interface States {
  modal: string
  selectedMember: Family
}

class HealthInsuranceRow extends React.Component<Props, States> {
  state = {
    modal: null,
    selectedMember: null,
  }

  toggleModal = () => {
    this.setState({
      modal: null,
    })
  }

  openAddInfo = member => {
    this.setState({
      modal: 'addInfo',
      selectedMember: member,
    })
  }

  handleUpdate = updated => {
    this.props.onUpdate(updated)
    this.setState({ modal: null })
  }

  handleInfoUpdate = (memberId, info) => {
    const { cartItem } = this.props

    this.props.onInfoUpdate(
        memberId === -1
            ? info // update the entire info
            : {
          ...(cartItem.additionalInfo || {}),
          [memberId]: info,
        }
    )
  }

  render() {
    const { cartItem, family, expirationNode, user } = this.props
    const { benefit, additionalInfo, disableDocumentation } = cartItem
    const memberIds = cartItem.getFamilyIds().sort()


    return (
      <React.Fragment>
        {memberIds.map((memberId, index) => {
          const member = family.find(item => item.id === Number(memberId))

          if (!member) return null

          return (
            <tr key={memberId}>
              {index === 0 && (
                <td
                  rowSpan={memberIds.length}
                  className="col-benefit text-center"
                >
                  <BenefitSimple benefit={benefit} />
                </td>
              )}
              <td>
                <FamilySummary family={member} />
              </td>

              <td className="text-center">

                {!disableDocumentation &&
                    <AdditionalInfoColumn
                      additionalInfo={additionalInfo && additionalInfo[memberId]}
                      benefit={benefit}
                      onUpdate={info => {
                        this.handleInfoUpdate(memberId, info)
                      }}
                      user={user}
                    />
                }
              </td>

              <td className="text-center">
                {expirationNode}
              </td>

              {index === 0 && (
                <td className="text-center" rowSpan={memberIds.length}>
                  {formatCurrency(cartItem.finalPrice)}
                </td>
              )}

              {index === 0 && (
                <td className="col-action" rowSpan={memberIds.length}>
                  <span
                    role="button"
                    className="text-success mr-3"
                    title="Edit"
                    onClick={() => {
                      this.setState({ modal: 'monthModal' })
                    }}
                  >
                    <i className="entypo-pencil" />
                  </span>

                  <span
                    role="button"
                    className="text-black"
                    title="Remove"
                    onClick={this.props.onDelete}
                  >
                    <img src="/img/remove.jpg" alt="cart" width="30" />
                  </span>
                </td>
              )}
            </tr>
          )
        })}

        {this.state.modal === 'monthModal' && (
          <MonthEditDialog
            cartItem={cartItem}
            toggle={this.toggleModal}
            EditComponent={FamilySelection}
            onUpdate={this.handleUpdate}
          />
        )}
      </React.Fragment>
    )
  }
}

export default HealthInsuranceRow
