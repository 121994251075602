import React, { useState } from 'react'
import { FormGroup, Modal, ModalBody, Label, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import './SignatureStep.scss'

type Props = {
  user: User
  onBack(): void
  onSubmit(): void
}

const SignatureStep: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()
  const [acceptPrivacy, setAcceptPrivacy] = useState(false)
  const [modal, setModal] = useState(false)
  const handlePrivacyCheck = e => {
    setAcceptPrivacy(e.target.checked)
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const {
    user: { company },
    onBack,
    onSubmit,
  } = props

  return (
    <div className="signature-step">
      <p
        className="mt-5 sign-text-info"
        dangerouslySetInnerHTML={{
          __html: t('cart.terms', { cutOffDate: company.cutOffDate }),
        }}
      />

      <div className="my-5 text-center">
        <button type="button" className="btn-none" onClick={onBack}>
          <img src="/img/back.jpg" alt="cart" width="50" /> {t('buttons.back')}
        </button>
        <button
          type="button"
          className="btn-none ml-3"
          onClick={onSubmit}
          disabled={!acceptPrivacy}
        >
          <img src="/img/sign.jpg" alt="cart" width="50" /> {t('buttons.sign')}
        </button>
      </div>
      <div className="text-center">
        <FormGroup check>
          <Label check>
            <Input type="checkbox" onChange={handlePrivacyCheck} />{' '}
            {getTranslate(company, 'privacyText', i18n.language)}
          </Label>
          <span className="ml-2 btn-link" onClick={toggleModal}>
            {t('buttons.see_details')}
          </span>
        </FormGroup>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="p-3">
          <button className="close" onClick={toggleModal} title="Close">
            ×
          </button>
        </div>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: getTranslate(company, 'privacyDetail', i18n.language),
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default SignatureStep
