import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { required } from 'redux-form-validators'
import { Link } from 'react-router-dom'
import { WithTranslation } from 'react-i18next'
import { login } from 'store/auth/actions'
import InputField from 'components/forms/InputField'
import './LoginPage.scss'
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue  } from "react-cookie-consent"
import { SubmissionError } from 'redux-form'
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { alertError } from 'utils/notify'
import CookiesMessage from 'templates/cookies_message'
import Turnstile from 'components/widgets/turnstile'


const __COOKIE_CONSENT_VERSION = "v3"

type Props = {
  error?: string
  handleSubmit(onSubmit: Function): any
  dispatch(action: any)
}

class LoginPage extends React.Component<Props & WithTranslation> {

    state = {
        modalCookies: false,
        captcha: null,
    }

    _getCookieConsent = () => {
        const consentedVersion = getCookieConsentValue()
        const cookieConsent = consentedVersion && consentedVersion === __COOKIE_CONSENT_VERSION

        if(consentedVersion && !cookieConsent)    { //the user has an old cookie version, forcing delete
            console.log("cookie consent version renewed: reseting")
            resetCookieConsentValue()
        }
        return cookieConsent
    }

    onSubmit = values => {
        const { captcha } = this.state
        
        if(process.env.REACT_APP_DISABLE_CAPTCHA !== '1' && !captcha) {
          alertError(null,"captcha no resuelto")
          return
        }

        const cookieConsent = this._getCookieConsent()
        if(!cookieConsent) {
          const is_mandatory = process.env.REACT_APP_COOKIE_CONSENT_MANDATORY === '1'
          if(is_mandatory)
          throw new SubmissionError({
            _error: "Es necesario aceptar las cookies.",
          })
        }
        return this.props.dispatch(login(values, captcha)).catch(err=>{
            //captcha must be reset
            this.resetCaptcha()
            console.error(err)
            throw err
        })
    }

    onCookiesAccepted = () => this.forceUpdate()

    onCaptcha = captcha => {
        this.setState({ captcha })

    }
    resetCaptcha = () => {
        if(window['turnstile']) {
            window['turnstile'].reset()
        }
        this.setState({ captcha: null })
    }

    getLegalButtons(t) {
        const showPrivacyButton = process.env.REACT_APP_SHOW_PRIVACY_BUTTON === '1'
        const showCookieLegalButton = process.env.REACT_APP_SHOW_COOKIE_LEGAL_BUTTON === '1'

        return(
            <div className="d-flex">
                <button
                    type="button"
                    className="legal-button-login mr-2"
                >
                        <span className="cp" title="legal">
                                   <a href="/assets/legal.pdf" target="_blank" className="legal-pdf">
                                    {t('MENU.LEGAL')}
                                  </a>

                        </span>
                </button>

                {showPrivacyButton && (

                    <button
                        type="button"
                        className="legal-button-login mr-2"
                    >
                                <span className="cp" title="privacy">
                                           <a href="/assets/privacy.pdf" target="_blank" className="legal-pdf">
                                            {t('MENU.PRIVACY')}
                                          </a>

                                </span>
                    </button>
                )}

                {showCookieLegalButton && (

                    <button
                        type="button"
                        className="legal-button-login mr-2"
                    >
                                <span className="cp" title="cookies">
                                           <a href="/assets/cookielegal.pdf" target="_blank" className="legal-pdf">
                                            Cookies
                                          </a>

                                </span>
                    </button>
                )}


            </div>
        )
    }

    render() {
    const { handleSubmit, error, t } = this.props
    const { captcha } = this.state

    const submitDisable = process.env.REACT_APP_DISABLE_CAPTCHA !== '1' && !captcha
    const cookieConsent = this._getCookieConsent()

    // @ts-ignore
        // @ts-ignore
        return (
        <div>

            <Modal
                className="cart-edit-modal"
                isOpen={!cookieConsent}
            >

                <ModalBody>
                    <CookiesMessage />
                </ModalBody>
                <ModalFooter>

                    <CookieConsent
                        disableStyles={true}
                        cookieValue={__COOKIE_CONSENT_VERSION}
                        buttonText={t('buttons.consent_lower')}
                        onAccept={ this.onCookiesAccepted }
                    >
                    </CookieConsent>

                </ModalFooter>
            </Modal>

          <div className="login-form text-center">
                <form id="form_login" onSubmit={handleSubmit(this.onSubmit)}>
                  <div className="ui stacked segment">

                      <input type="hidden" name="app" value="users" />
                      {error && (
                          <div className="alert alert-danger" role="alert">
                              {error}
                          </div>
                      )}

                      <React.Fragment>

                          <div className="form-group">
                              <Field
                                  type="text"
                                  component={InputField}
                                  icon="entypo-mail"
                                  name="email"
                                  placeholder="Email/DNI"
                                  id="username"
                                  validate={[required()]}
                              />
                          </div>

                          <div className="form-group">
                              <Field
                                  type="password"
                                  component={InputField}
                                  icon="entypo-key"
                                  name="password"
                                  placeholder="Password"
                                  validate={required()}
                              />
                          </div>

                          <button disabled={ submitDisable } className="btn" type="submit">
                              Acceder
                          </button>


                      </React.Fragment>


                      <div className="d-flex justify-content-center my-3">
                          <Turnstile
                              onVerify={ this.onCaptcha }
                              onError={ this.resetCaptcha }
                              onExpire={ this.resetCaptcha }
                              onTimeout={ this.resetCaptcha }
                          />

                      </div>

                  </div>
                  <Link to="forgot-password">{t('buttons.forgot_password')}</Link>
                </form>


          </div>


            <div className="CookieConsent loginBottomNavbar loginBottomNavbarCustom">
                <div className="loginBottomNavbarInner">
                    <div className="d-flex justify-content-between">
                        {this.getLegalButtons(t)}
                    </div>
                </div>
            </div>





        </div>
    )
  }
}

export default reduxForm({
  form: 'LoginForm',
})(LoginPage)
