import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import access from 'safe-access'
import ContractPrice from 'components/BenefitDetail/ContractPrice'


import { showNotify } from 'utils/notify'
import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/actions'
import BenefitDescModal from 'components/BenefitDetail/BenefitDescModal'
import MonthSelect from 'components/BenefitDetail/MonthSelect'
import { getTranslate } from 'helpers/translate'

interface Props {
  contract: HealthInsuranceContract
  editable?: boolean
  family: Family[]
  simulation: Simulation
  showBack: boolean
  user: User
  dispatch(action: any): void
  onChange(): void
  onPrev(): void
}

interface States {
  selectedBenefit: Benefit
}

class FamilySelection extends React.Component<Props & WithTranslation, States> {
  static defaultProps = {
    editable: true,
    supplementaries: [],
    showBack: false,
  }

  state = { selectedBenefit: null }

  constructor(props) {
    super(props)

    this.props.dispatch(getFamily())
  }

  toggleModal = () => {
    this.setState({ selectedBenefit: null })
  }

  handleMonthChange = (member: Family, benefit: Benefit, month: number) => {
    const {
      contract,
      family,
      simulation: { available, flexibleBag, topBag, availableTop, fixedSalary},
      t,
      onChange,
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    const maxPossiblePrice = isTaxAdvantage ? flexibleBag : topBag
    let availableMoney = isTaxAdvantage ? available : availableTop
    let isNoLimits = false
    if (!isTaxAdvantage && !topBag) {
      availableMoney = fixedSalary
      isNoLimits = true
    }

    const oldPrice = contract.price
    const oldItems = contract.items

    // update the month
    contract.setStartMonth(member, benefit, month)

    // update family's month if period is same with the main member
    if (
      member.id === 0 &&
      benefit.isBenefit() &&
      benefit.periodOptions === 'same'
    ) {
      for (let i = 1; i < family.length; i += 1) {
        const startMonth = contract.getStartMonth(family[i].id, benefit.id)
        if (startMonth < month) {
          contract.setStartMonth(family[i], benefit, month)
        }
      }
    }

    if (!isNoLimits && contract.price > Math.min(availableMoney + oldPrice, maxPossiblePrice)) {
      contract.updateItems(oldItems)
      showNotify(t('products.price_over_error'), 'error')
    } else {
      onChange()
    }
  }

  openModal = (benefit: Benefit) => {
    this.setState({ selectedBenefit: benefit })
  }

  getBenefitOption = (benefit: Benefit, member: Family) => {
    const { contract } = this.props
    const monthsAvailability = [...contract.benefit.monthsAvailability]

    // make supplementaries select after the main benefit date
    if (benefit.isSupplementary()) {
      const mainBenefitStartMonth = contract.getStartMonth(
        member.id,
        contract.benefit.id
      )

      for (let i = 0; i < mainBenefitStartMonth; i += 1) {
        monthsAvailability[i] = false
      }
    } else if (member.id > 0 && benefit.periodOptions === 'same') {
      const mainBenefitStartMonth = contract.getStartMonth(
        0,
        contract.benefit.id
      )
      for (let i = 0; i < mainBenefitStartMonth; i += 1) {
        monthsAvailability[i] = false
      }
    }

    let disabled = false
    if(member.id > 0){//family member
      disabled = benefit.whoAffect !== 'both'
          || contract.isMemberContracted(member.id, benefit.id)
          || !contract.isMemberSelected(0,benefit.id) //employee selected?
    }else{ //employee
      disabled = contract.isMemberContracted(0,benefit.id) //employee contracted?
    }

    return (
      <td key={benefit.id} className="text-center">
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-3">
            {formatCurrency(benefit.getUserPrice(member))}
          </span>
          <MonthSelect
            availability={monthsAvailability}
            value={contract.getStartMonth(member.id, benefit.id)}
            disabled={disabled}
            onChange={value => {
              this.handleMonthChange(member, benefit, value)
            }}
          />
        </div>
      </td>
    )
  }

  render() {
    const { contract, family, showBack, t, i18n } = this.props
    const { benefit } = contract
    const benefits = [benefit, ...benefit.supplementaries]

    return (
      <div>
        <table className="table table-bordered responsive">
          <thead>
            <tr>
              <th>{t('products.person')}</th>
              {benefits.map(benefit => (
                <th key={benefit.id} className="text-center">
                  {getTranslate(benefit, 'name', i18n.language)}
                  {benefit.parentId > 0 && (
                    <span
                      className="badge badge-info btn-help ml-2"
                      role="button"
                      title="Click to see the details"
                      onClick={() => {
                        this.openModal(benefit)
                      }}
                    >
                      ?
                    </span>
                  )}
                </th>
              ))}
              <th className="text-center">{t('products.yearly')}</th>
            </tr>
          </thead>
          <tbody>
            {family.map(member => (
              <tr key={member.id}>
                <td>
                  {member.fullName}
                  {member.id > 0 && (
                    <small className="ml-2">({member.relationship})</small>
                  )}
                </td>

                {benefits.map(benefit =>
                  this.getBenefitOption(benefit, member)
                )}

                <td className="text-center">
                  {formatCurrency(contract.getFamilyTotalPrice(member.id))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-center">
          {showBack && (
            <button
              type="button"
              className="btn-none mr-4"
              onClick={this.props.onPrev}
            >
              <img src="/img/back.jpg" alt="btn" width="50" />{' '}
              {t('buttons.back')}
            </button>
          )}

          <Link to="/profile?tab=family" className="btn-none">
            <img src="/img/add-family.jpg" alt="btn" width="50" />
            {t('buttons.add_member')}
          </Link>
        </div>

        <div className="text-center my-3">

          <ContractPrice price={contract.price} discount={contract.userBag} unit={t('products.year')}/>

        </div>

        {this.state.selectedBenefit && (
          <BenefitDescModal
            benefit={this.state.selectedBenefit}
            isOpen={true}
            toggle={this.toggleModal}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(FamilySelection)
