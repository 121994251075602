import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { generateContract } from 'models/Contract'
import { getFamily } from 'store/families/reducer'
import { selectProfile } from 'store/profile/reducer'
import OtherProductRow from 'pages/Cart/CartEditStep/OtherProductRow'
import KindergartenRow from 'pages/Cart/CartEditStep//KindergartenRow'
import HealthInsuranceRow from 'pages/Cart/CartEditStep//HealthInsuranceRow'
import './ContractChangeConfirm.scss'

type Props = {
  contract: Contract
  onBack(): void
  onUpdate(additionalInfo: any): void
}
const ContractChangeConfirm: React.FC<Props> = ({
  contract,
  onBack,
  onUpdate,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectProfile)
  const family = useSelector(getFamily)
  const [additionalInfo, setAdditionalInfo] = useState(contract.additionalInfo)

  const handleUpdate = () => {
    onUpdate(additionalInfo)
  }

  const handleInfoUpdate = (additionalInfo: any) => {
    setAdditionalInfo(additionalInfo)
  }

  const handleDelete = () => {}

  let Row
  if (contract.product === 'kindergarten') {
    Row = KindergartenRow
  } else if (contract.product === 'health-insurance') {
    Row = HealthInsuranceRow
  } else {
    Row = OtherProductRow
  }

  const tempContract = generateContract({
    ...contract,
    additionalInfo,
  })

  return (
    <div className="mt-5">
      <table className="table responsive v-middle table-contract-confirm">
        <colgroup>
          <col width="0px" />
          <col width="auto" />
          <col width="auto" />
          <col width="80px" />
          <col width="0px" />
        </colgroup>
        <thead>
          <tr>
            <th className="d-none" />
            <th>{t('cart.beneficiary_info')}</th>
            <th className="text-center">{t('cart.additional_info')}</th>
            <th className="text-center">{t('cart.total')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <Row
            cartItem={tempContract}
            user={user}
            family={family}
            dispatch={dispatch}
            onUpdate={() => {}}
            onInfoUpdate={handleInfoUpdate}
            onDelete={handleDelete}
          />
        </tbody>
      </table>

      <div className="text-center">
        <button type="button" className="btn-none mr-5" onClick={onBack}>
        <img src="/img/back.jpg" alt="cart" width="50" />{' '} {t('buttons.back_simulation')}
        </button>
        <button
          type="button"
          className="btn-none px-5"
          onClick={handleUpdate}
        >
          <img src="/img/cart.jpg" alt="cart" width="50" />{' '}
          {t('buttons.update_contract')}
        </button>
      </div>
    </div>
  )
}

export default ContractChangeConfirm
