import React, { ReactNode } from 'react'
import { initialize as initializeForm } from 'redux-form'
import { withTranslation, WithTranslation } from 'react-i18next'
import moment from 'moment'

import { formatCurrency } from 'utils/number'
import { showNotify } from 'utils/notify'
import BenefitSimple from 'components/benefits/BenefitSimple'
import FamilySummary from 'components/profile/FamilySummary'
import Kindergarten from 'pages/Products/Kindergarten'
import MonthEditDialog from '../MonthEditDialog'
import KindergartenAddInfo from './KindergartenAddInfo'

interface Props {
  cartItem: Contract
  family: Family[]
  dispatch(action: any): any
  onUpdate(updated: Contract): void
  onInfoUpdate(info: any): void
  onDelete(): void
  expirationNode: ReactNode
}

interface States {
  modal: string
  selectedChild: Family
}

class KindergartenRow extends React.Component<Props & WithTranslation, States> {
  state = {
    modal: null,
    selectedChild: null,
  }

  toggleModal = (name: string) => {
    this.setState({
      modal: this.state.modal === name ? null : name,
    })
  }

  openAddInfo = (child: Family, additionalInfo: any) => {
    // initalize additional info form
    this.props.dispatch(
      initializeForm('KindergartenAddInfo', additionalInfo || {})
    )

    // show the modal
    this.setState({
      modal: 'addInfo',
      selectedChild: child,
    })
  }

  editChild = (child: Family) => {
    this.setState({
      modal: 'familyModal',
      selectedChild: child,
    })
  }

  handleUpdate = (updated: Contract) => {
    this.props.onUpdate(updated)
    this.setState({ modal: null })
  }

  handleFetchChild = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) return

    const { cartItem } = this.props
    const additionalInfo =
      cartItem.additionalInfo &&
      // eslint-disable-next-line
      cartItem.additionalInfo[e.target.value]

    this.props.dispatch(
      initializeForm('KindergartenAddInfo', additionalInfo || {})
    )
  }

  handleAddInfoUpdate = (childInfo: any) => {
    const { cartItem } = this.props
    const { selectedChild } = this.state

    const additionalInfo = cartItem.additionalInfo || {}
    additionalInfo[selectedChild.id] = childInfo

    this.props.onInfoUpdate(additionalInfo)
    this.setState({ modal: null })
  }

  handleChildValidate = (values: Family) => {
    if (moment().diff(values.birthday, 'years') > 2) {
      showNotify('La edad debe ser inferior a 3 años', 'error')
      return false
    }
    return true
  }

  render() {
    const { cartItem, family, expirationNode, t } = this.props
    const { benefit } = cartItem
    const children = cartItem
      .getFamilyIds()
      .map(childId => family.find(item => item.id === Number(childId)))

    return (
      <React.Fragment>
        {children.map((child, index) => {
          if (!child) return null

          const additionalInfo = cartItem.additionalInfo[child.id]

          return (
            <tr key={child.id}>
              {index === 0 && (
                <td
                  rowSpan={children.length}
                  className="col-benefit text-center"
                >
                  <BenefitSimple benefit={cartItem.benefit} />
                </td>
              )}
              <td>
                <FamilySummary
                  family={child}
                  hideRelationship
                  validate={this.handleChildValidate}
                />
              </td>
              <td className="text-center">
                <div className="mb-3">
                  {!additionalInfo ? (
                    <>
                      <img src="/img/error.jpg" alt="cart" width="50" />
                      <span className="label label-danger">
                        {t('cart.pending')}
                      </span>
                    </>
                  ) : (
                    <>
                      <img src="/img/ok.jpg" alt="cart" width="50" />
                      <span className="label label-success">
                        {t('cart.completed')}
                      </span>
                    </>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-default btn-icon"
                  onClick={() => {
                    this.openAddInfo(child, additionalInfo)
                  }}
                >
                  {t('buttons.update')}
                  <i className="entypo-pencil" />{' '}
                </button>
              </td>
              <td className="text-center">
                {expirationNode}
              </td>
              {index === 0 && (
                <td className="text-center" rowSpan={children.length}>
                  {formatCurrency(cartItem.finalPrice)}
                </td>
              )}
              {index === 0 && (
                <td className="col-action" rowSpan={children.length}>
                  <span
                    role="button"
                    className="text-success mr-3"
                    title="Edit"
                    onClick={() => {
                      this.toggleModal('monthModal')
                    }}
                  >
                    <i className="entypo-pencil" />
                  </span>

                  <span
                    role="button"
                    className="text-black"
                    title="Remove"
                    onClick={this.props.onDelete}
                  >
                    <img src="/img/remove.jpg" alt="cart" width="30" />
                  </span>
                </td>
              )}
            </tr>
          )
        })}

        {this.state.modal === 'addInfo' && (
          <KindergartenAddInfo
            isOpen={true}
            toggle={() => {
              this.toggleModal('addInfo')
            }}
            providerWebsite={benefit.providerWebsite}
            children={children}
            child={this.state.selectedChild}
            onFetchChild={this.handleFetchChild}
            onSubmit={this.handleAddInfoUpdate}
          />
        )}

        {this.state.modal === 'monthModal' && (
          <MonthEditDialog
            cartItem={cartItem}
            toggle={() => {
              this.toggleModal('monthModal')
            }}
            EditComponent={Kindergarten}
            onUpdate={this.handleUpdate}
          />
        )}
      </React.Fragment>
    )
  }
}

export default withTranslation()(KindergartenRow)
