/* eslint react/no-array-index-key: 0 */
import React from 'react'
import { Field } from 'redux-form'
import { required } from 'redux-form-validators'
import { useTranslation } from 'react-i18next'
import { showNotify } from 'utils/notify'

import SelectField from 'components/forms/SelectField'
import InputField from 'components/forms/InputField'
import DateField from 'components/forms/DateField'

type Props = {
  family: Family[]
  idsFamilyWithContracts: any[]
  fields: {
    push(family: any): void
    remove(index: any): void
    get(index: any): any
    map: any
  }
}

const Family: React.FC<Props> = ({ fields, family, idsFamilyWithContracts }) => {
  const { t } = useTranslation()
  const handleAddMember = () => {
    fields.push({
      relationship: 'spouse',
      idType: 'DNI',
      disabilityType: false,
      active: true
    })
  }

  const clickUpdate = () => {
    document.getElementById('hidden-submit-button').click()
  }

  const handleRemoveMember = (index) => {

    const member = {
      ...fields.get(index),
      active:false
    }
    
    const memberHasContract = (idsFamilyWithContracts || []).includes(member.id)

    if(memberHasContract){
      return showNotify('' +
          'No se puede borrar a un familiar que tiene un contrato activo o en el carrito. ' +
          'Por favor contacte con el administrador','warning')
    }

    if (window.confirm('Seguro que quieres eliminar el familiar?')) {
      fields.remove(index)
      setTimeout(()=>{clickUpdate()},500)
    }
  }

  return (
    <div>
      {fields.map((member, index) => (
          family[index].active && <React.Fragment key={index}>
          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.relationship')}
            </label>
            <div className="col-sm-5">
              <Field component={SelectField} name={`${member}.relationship`}>
                <option value="spouse">{t('profile.spouse')}</option>
                <option value="child">{t('profile.child')}</option>
                <option value="domestic_partner">
                  {t('profile.domestic_partner')}
                </option>
              </Field>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.first_name')}
            </label>
            <div className="col-sm-5">
              <Field
                component={InputField}
                name={`${member}.firstName`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.last_name')}
            </label>
            <div className="col-sm-5">
              <Field
                component={InputField}
                name={`${member}.lastName1`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label">
              {t('profile.last_name_2')}
            </label>
            <div className="col-sm-5">
              <Field
                component={InputField}
                name={`${member}.lastName2`}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              className="col-sm-3 control-label required"
              dangerouslySetInnerHTML={{ __html: t('profile.id') }}
            />
            <div className="col-sm-5">
              <Field
                component={InputField}
                name={`${member}.personalID`}
                validate={required()}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label required">
              {t('profile.birthday')}
            </label>
            <div className="col-sm-5">
              <Field
                component={DateField}
                name={`${member}.birthday`}
                validate={required()}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label text-capitalize">
              {t('profile.disability')}
            </label>
            <div className="col-sm-5">
              <Field component={SelectField} name={`${member}.disabilityType`}>
                <option value="false">{t('profile.No')}</option>
                <option value="true">{t('profile.Yes')}</option>
              </Field>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 control-label">
              % {t('profile.disability')}
            </label>
            <div className="col-sm-5">
              <div className="input-group mb-3">
                <Field
                  type="number"
                  component={InputField}
                  name={`${member}.disability`}
                  min="0"
                  max="100"
                  disabled={`${family[index].disabilityType}` !== 'true'}
                />
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-offset-3 col-sm-5">
              <button
                type="button"
                className="btn btn-none"
                onClick={() => handleRemoveMember(index)}
              >
                <img src="/img/remove-family.jpg" alt="guardar" width="50" />
                {t('buttons.delete')}
              </button>

              <button type="submit" id="submit-button" className="btn-none ml-4">
                <img src="/img/guardar.jpg" alt="guardar" width="50" />
                {t('buttons.update')}
              </button>

            </div>

          </div>
          <hr />
        </React.Fragment>
      ))}

      <div className="row">
        <div className="col-sm-offset-3 col-sm-5">
          <button type="button" className="btn-none" onClick={handleAddMember}>
            <img src="/img/add-family.jpg" alt="guardar" width="50" />
            {t('buttons.add_member')}
          </button>


          <button type="submit" id="hidden-submit-button" className="btn-none ml-4 hidden" ></button>

        </div>
      </div>
    </div>
  )
}

export default Family
