import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { required } from 'redux-form-validators'
import { Link } from 'react-router-dom'
import { alertError } from 'utils/notify'
import { forgotPassword } from 'store/auth/actions'
import InputField from 'components/forms/InputField'
import './ForgotPasswordPage.scss'
import Turnstile from "components/widgets/turnstile"


class ForgotPasswordPage extends React.Component {
  state = {
    success: false,
    capcha: null
  }

  onSubmit = values => {
    const { captcha } = this.state

    if(process.env.REACT_APP_DISABLE_CAPTCHA !== '1' && !captcha) {
      alertError(null,"captcha no resuelto")
      return
    }

    return this.props
      .dispatch(forgotPassword(values, captcha))
      .then(() => {
        this.setState({
          success: true,
        })
      })
      .catch(res => {
        this.resetCaptcha()
        const message = res.error || 'No se puede procesar la petición. Revisa el formato de correo'
        console.error(message)
        throw new SubmissionError({
          _error: message,
        })
      })
  }

  resetCaptcha = () => {
    if(window['turnstile']) {
      window['turnstile'].reset()
    }
    this.setState({ captcha: null })
  }

  onCaptcha = captcha => {
    this.setState({ captcha })
  }


  render() {
    const { handleSubmit, error, t } = this.props
    const { success, captcha } = this.state
    const submitDisable = process.env.REACT_APP_DISABLE_CAPTCHA !== '1' && !captcha

    return (
      <div className="login-form text-center">
        <form id="form_login" onSubmit={handleSubmit(this.onSubmit)}>
          {success ? (
            <div>The reset email was sent. Please check your mail box.</div>
          ) : (
            <div className="ui stacked segment">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              <React.Fragment>
                <div className="form-group">
                  <Field
                      type="text"
                      component={InputField}
                      icon="entypo-mail"
                      name="email"
                      placeholder="Email"
                      validate={[required()]}
                  />
                </div>

                <button disabled={ submitDisable } className="btn mb-2" type="submit">
                  {t("buttons.reset_password")}
                </button>

              </React.Fragment>

            </div>
          )}

          <div className="d-flex justify-content-center">
            <Turnstile
                onVerify={ this.onCaptcha }
                onError={ this.resetCaptcha }
                onExpire={ this.resetCaptcha }
                onTimeout={ this.resetCaptcha }
            />

          </div>


          <Link to="login">{t("buttons.back_to_login")}</Link>

        </form>

      </div>
    )
  }
}

ForgotPasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'ForgotPasswordForm',
})(ForgotPasswordPage)
