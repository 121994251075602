import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'
import { selectProfile } from 'store/profile/reducer'
import { updateProfile } from 'store/profile/actions'
import { selectMenu } from "store/menu/reducer"
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import TopBar from './TopBar'
import BottomBar from './BottomBar'

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import { logout } from 'store/auth/actions'

import MenuItem from './MenuItem'

import './Menu.scss'
import { getMenu } from "store/menu/actions"
import { getFamily } from "store/families/actions"

type Props = {
  user: User
  items: any,
  dispatch(action: any): any
}

class Menu extends React.Component<Props & WithTranslation> {
  state = {
    hasError: false,
    modalCookies: false
  }

  constructor(props) {
    super(props)
    this.props.dispatch(getFamily())
    this.props.dispatch(getMenu())

  }

  componentDidMount() {
      if(this.props.user && !this.props.user.cookiesAccepted) this.setState({ modalCookies: true })
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }

  handleLogout = () => {
    logout()
  }

  toggleCookiesModal = () => {
    this.setState({ modalCookies: false })
  }

  acceptCookies = () => {
    this.props.dispatch(updateProfile({ cookiesAccepted:true }))
    this.setState({ modalCookies: false })
  }

    render() {
    const { user, items, t, i18n } = this.props
    const { modalCookies } = this.state
    const { company } = user

    if(!items) return null

    const menuItems = items.map(el=>{
          el.text = el.name ? el.name[i18n.language]: t(`MENU.${el.id}`)
          return el
    })

    const capitalize = (string) => {
        const ret = string ? string.toLowerCase() : ''
        return ret.charAt(0).toUpperCase() + ret.slice(1)
    }

    const itemsHtml = menuItems.map(item => (
        <Link key={`menu-item-${item.id}`} to={item.link || '/'}>
          <MenuItem
              key={item.id}
              title={item.text}
              icon={item.icon}
          />
        </Link>
    ))

      return (

          <div className={"menu-page-container"}>

          <Helmet>
            <title>{company.name}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
          </Helmet>

            <TopBar {...this.props} logout={this.handleLogout} />

              <Modal
                  className="cart-edit-modal"
                  isOpen={modalCookies}
                  toggle={this.toggleCookiesModal}
              >
                  <ModalHeader toggle={this.toggleCookiesModal}>
                      <div className="cookies-title mb-2">Aviso de privacidad </div>
                  </ModalHeader>
                  <ModalBody>
                      <div className="heading4 mb-2 cookies-body">
                          Este sitio web puede utilizar cookies técnicas (pequeños archivos de
                          información que el servidor envía al ordenador de quien accede a la página)
                          para llevar a cabo determinadas funciones que son consideradas
                          imprescindibles para el correcto funcionamiento y visualización del sitio. Las
                          cookies utilizadas tienen, en todo caso, carácter temporal, con la única finalidad
                          de hacer más eficaz la navegación, y desaparecen al terminar la sesión del
                          usuario. En ningún caso, estas cookies proporcionan por sí mismas datos de
                          carácter personal y no se utilizarán para la recogida de los mismos.
                          Mediante el uso de cookies también es posible que el servidor donde se
                          encuentra la web reconozca el navegador utilizado por el usuario con la
                          finalidad de que la navegación sea más sencilla, permitiendo, por ejemplo, el
                          acceso de los usuarios que se hayan registrado previamente a las áreas,
                          servicios, promociones o concursos reservados exclusivamente a ellos sin tener
                          que registrarse en cada visita.
                      </div>

                  </ModalBody>
                  <ModalFooter>

                      <button
                          type="button"
                          className="btn btn-default"
                          onClick={this.toggleCookiesModal}
                      >
                          {t('buttons.close')}
                      </button>

                      <button
                          type="button"
                          className="btn btn-default"
                          onClick={this.acceptCookies}
                      >
                          {t('buttons.accept')}
                      </button>
                  </ModalFooter>
              </Modal>


              <MediaQuery minWidth={550}>
                <div className="menu-top">
                    <img alt='logo' src={ company.logo ? `/api/storage/images/${company.logo}`: `/img/logo.png` } height={50} />
                    <p className="menu-paragraph">
                        Hola <b>{capitalize(user.firstName)}</b>,
                        Bienvenido/a a tu plan de retribución flexible. En el apartado “Documentación” encontrarás toda la información necesaria. Recuerda que en primer lugar debes cumplimentar el apartado de “Información personal”
                    </p>
                </div>
            </MediaQuery>



          <MediaQuery minWidth={550}>
            <div className="d-flex align-content-center justify-content-center flex-wrap menu-container">
              {itemsHtml}
            </div>
          </MediaQuery>


          <MediaQuery maxWidth={550}>
            <div className="menu-container-mobile center" >
              {itemsHtml}
            </div>
          </MediaQuery>


          <BottomBar {...this.props} />


        </div>
    )


  }
}

const mapStateToProps = state => ({
  user: selectProfile(state),
  items: selectMenu(state),

})

export default withTranslation()(connect(mapStateToProps)(Menu))
