import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onClick(action: string): void
}

const ContractButtons: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div className="cart-buttons mt-3">
      <button
        type="button"
        className="btn-none mr-4"
        onClick={() => {
          onClick('update')
        }}
      >
        <img src="/img/cart.jpg" alt="btn" width="50" />
        {t('buttons.update_contract')}
      </button>
    </div>
  )
}

export default ContractButtons
