import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber } from 'utils/number'
import { getFamily } from 'store/families/actions'
import Calculator from 'models/Calculator'
import SimulatorDetail from './SimulatorDetail'
import './CalcDetail.scss'

type Props = {
  user: User
  family: Family[],
  simulatorItems: Contract[]
  benefits: Benefit[]
}

const CalcDetail: React.FC<Props> = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getFamily())
  })

  const { user, children, simulatorItems, family, benefits } = props

  if (!user.fixedSalary || user.fixedSalary < user.flexibleBag) {
    return (
      <div className="alert alert-warning" role="alert">
        {t('calculator.not_available')} -&nbsp;
        <Link to="/profile?tab=employee">{t('calculator.edit_profile')}</Link>
      </div>
    )
  }

  const calculator = new Calculator(user, children, family)
  const {
    withFlex,
    withoutFlex,
    advantageProducts,
    otherProducts,
    contracts,
    contractsWithoutFlex
  } = calculator.calculate(simulatorItems || [])

  const categories = user && user.products.map(product => product.category)
  const isExentProducts = benefits && !!benefits.find(benefits => benefits.isTaxAdvantage)

  return isExentProducts ? (
    <div className="invoice">
      <h3 className="mb-4 text-center">
        <img
          className="mr-1"
          src="/img/calendar.jpg"
          alt="calendar"
          width="50"
        />
        {t('calculator.header')}
      </h3>
      <div className="row no-gutters">
        <div className="col-md-6">
          <SimulatorDetail
            title={t('calculator.without_remuneration')}
            data={withoutFlex}
            contracts={contractsWithoutFlex}
            advantageProducts={advantageProducts}
            otherProducts={otherProducts}
            categories={categories}
          />
        </div>

        <div className="col-md-6">
          <SimulatorDetail
            title={t('calculator.with_remuneration')}
            data={withFlex}
            advantageProducts={advantageProducts}
            otherProducts={otherProducts}
            contracts={contracts}
            categories={categories}
            withFlex
          />
        </div>
      </div>

      <div className="text-center mt-3">
        <div className="calc-total">
          <div>
            {t('calculator.increase_dis_income')}:
            <strong className="ml-3">
              {formatCurrency(
                withFlex.netAvailableSalary - withoutFlex.netAvailableSalary
              )}
            </strong>
          </div>
          <div>
            {t('calculator.equi_salary_increase')}:
            <strong className="ml-3">
              {formatNumber(
                (withFlex.netAvailableSalary / withoutFlex.netAvailableSalary -
                  1) *
                  100
              )}
              %
            </strong>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default CalcDetail
