import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { getTranslate } from 'helpers/translate'
import Panel from 'components/Panel'
import './SimulatorDetail.scss'


type Props = {
  title: string
  data: any
  advantageProducts: any
  otherProducts: any
  contracts: any
  categories: any[]
  withFlex?: boolean
}

const SimulatorDetail: React.FC<Props> = ({
  title,
  data,
  advantageProducts,
  otherProducts,
  contracts,
  categories,
  withFlex,
}) => {
  const { t, i18n } = useTranslation()

  const getCategoryName = (product) => {
    if (categories && product) {
      const category = categories.find(categoryData => categoryData.alias === product)
      if (!category) {
        return product
      }
      return getTranslate(category, 'name', i18n.language)
    }
    return product
  }

  return (
    <Panel title={title} color="simulator">
      <div>
        <label className="simulator-detail-label">{t('calculator.fixed_salary')}</label>
        <span className="pull-right">{formatCurrency(data.fixedSalary)}</span>
      </div>

      <div>
        <label className="simulator-detail-label">{t('calculator.variable_salary')}</label>
        <span className="pull-right">
          {formatCurrency(data.variableSalary)}
        </span>
      </div>

      <div className="text-black pb-1">
        <label className="simulator-detail-label">{t('calculator.flexible_products')}</label>
        <span className="pull-right">
          {formatCurrency(withFlex ? -data.advantageProducts : 0)}
        </span>
      </div>

      {Object.keys(advantageProducts).map(product => (
        <div key={product} className={`py-0  ${!withFlex && 'invisible'}`}>
          <label className="simulator-detail-label">{getCategoryName(product)}</label>
          <span className="pull-right">

            {formatCurrency(-advantageProducts[product])}
          </span>
        </div>
      ))}

      <div className="text-black">
        <label className="simulator-detail-label">{t('calculator.total_gross_salary')}</label>
        <span className="pull-right">{formatCurrency(data.grossSalary)}</span>
      </div>

      <div>
        <label className="simulator-detail-label">{t('calculator.social_security')}</label>
        <span className="pull-right">
          {formatCurrency(-data.socialSecurity)}
        </span>
      </div>

      <div>
        <label className="simulator-detail-label">{t('calculator.pit')}</label>
        <span className="pull-right">{formatCurrency(-data.PIT)}</span>
      </div>

      <div className={`${!withFlex && 'invisible'}`}>
        <label className="simulator-detail-label">{t('calculator.withholding_account')}</label>
        <span className="pull-right">
          {formatCurrency(-data.holdingOnAccount)}
        </span>
      </div>

      <div className="text-black">
        <label className="simulator-detail-label">{t('calculator.net_salary')}</label>
        <span className="pull-right">{formatCurrency(data.netSalary)}</span>
      </div>

      <div className="text-black pb-1">
        <label className="simulator-detail-label">{t('calculator.flexible_products')}</label>
        <span className="pull-right">
          {formatCurrency(data.flexibleProducts) }
        </span>
      </div>

      {Object.keys(advantageProducts).map(product => (
          withFlex ? (<div key={product} className={`py-0  `}>
          <label className="simulator-detail-label">{getCategoryName(product)}</label>
          <span className="pull-right">
            { formatCurrency(0)}
          </span>
        </div>) :  (<div key={product} className={`py-0  `}>
            <label className="simulator-detail-label">{getCategoryName(product)}</label>
            <span className="pull-right">
            { formatCurrency(-contracts[product].price)}
          </span>
          </div>)
      ))}

      <div className="text-black pb-1">
        <label className="simulator-detail-label">{t('calculator.non_flexible_products')}</label>
        <span className="pull-right">
          {formatCurrency(data.otherProducts ? -data.otherProducts : 0)}
        </span>
      </div>

      {Object.keys(otherProducts).map(product => (
        <div key={product} className="py-0">
          <label className="simulator-detail-label">{getCategoryName(product)}</label>
          <span className="pull-right">
            {formatCurrency(-otherProducts[product])}
          </span>
        </div>
      ))}

      <div className="text-black">
        <label className="simulator-detail-label">{t('calculator.net_available_salary')}</label>
        <span className="pull-right">
          {formatCurrency(data.displayNetAvailableSalary)}
        </span>
      </div>
    </Panel>
  )
}

SimulatorDetail.defaultProps = {
  withFlex: false,
}

export default SimulatorDetail
