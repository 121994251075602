import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { getFamily } from 'store/families/actions'
import { getCalculation, getContractItems } from 'store/simulator/reducer'
import CurrentSituation from './CurrentSituation'
import News from './News'
import Alerts from './Alerts'
import PendingSimulations from './PendingSimulations'
import MediaQuery from 'react-responsive'
import './HomePage.scss'

type Props = {
  user: User
}

const HomePage: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isExentProducts = user && user.products.find(product => product.PL === 'Exent')

  useEffect(() => {
    dispatch(getFamily())
  })

  const { saving, pending } = useSelector(getCalculation)
  const contractItems = useSelector(getContractItems)
  const contracted = contractItems.filter(
    (item: Contract) => item.status === 'contracted'
  )
  const signed = contracted.filter((item: Contract) => item.isSigned())

  return (
    <div className="home-page">

      <MediaQuery minWidth={768}>

        <div className="d-flex flex-wrap">
          <div className="stats-wrapper">
            <div className="tile-stats contracted-products">
              <div className="num">{signed.length}</div>
              <h3>{t('HOME.CONTRACTED_PRODUCTS')}</h3>
            </div>
          </div>

          <div className="stats-wrapper">
            <div className="tile-stats pending-products">
              <div className="num">{contracted.length - signed.length}</div>
              <h3>{t('HOME.PENDING_SIGNATURE')}</h3>
            </div>
          </div>

          <div className="stats-wrapper">
            <div className="tile-stats pending-products">
              <div className="num">
                {contractItems.length - contracted.length}
              </div>
              <h3>{t('HOME.PENDING_PRODUCTS')}</h3>
            </div>
          </div>

          {isExentProducts && (
            <>
              <div className="stats-wrapper">
                <div className="tile-stats saving">
                  <div className="num">{formatCurrency(saving)}</div>
                  <h3>{t('HOME.SAVING')}</h3>
                </div>
              </div>

              <div className="stats-wrapper">
                <div className="tile-stats pending-saving">
                  <div className="num">{formatCurrency(pending)}</div>
                  <h3>{t('HOME.PENDING_SAVING')}</h3>
                </div>
              </div>
            </>
          )}
        </div>

      </MediaQuery>

      <MediaQuery maxWidth={768}>

        <div className="d-flex flex-wrap">

          <div className="stats-wrapper-mobile d-flex flex-row">

            <div className="stats-num">
              {signed.length}
            </div>
            <div className="stats-label-mobile">
              {t('HOME.CONTRACTED_PRODUCTS')}
            </div>

          </div>

          <div className="stats-wrapper-mobile d-flex flex-row">

            <div className="stats-num">
              {contracted.length - signed.length}
            </div>
            <div className="stats-label-mobile">
              {t('HOME.PENDING_SIGNATURE')}
            </div>

          </div>

          <div className="stats-wrapper-mobile d-flex flex-row">

            <div className="stats-num">
              {contractItems.length - contracted.length}
            </div>
            <div className="stats-label-mobile">
              {t('HOME.PENDING_PRODUCTS')}
            </div>

          </div>

          <div className="stats-wrapper-mobile d-flex flex-row">

            <div className="stats-num">
              {formatCurrency(saving)}
            </div>
            <div className="stats-label-mobile">
              {t('HOME.SAVING')}
            </div>

          </div>

          <div className="stats-wrapper-mobile d-flex flex-row">

            <div className="stats-num">
              {formatCurrency(pending)}
            </div>
            <div className="stats-label-mobile">
              {t('HOME.PENDING_SAVING')}
            </div>
          </div>

        </div>

      </MediaQuery>

      <div className="row mt-3">
        <div className="col-lg-7">
          <CurrentSituation />
        </div>
        <div className="col-lg-5">
          <News />
          <div className="mt-3" />
          <Alerts />
          <PendingSimulations />
        </div>
      </div>
    </div>
  )
}

export default HomePage
