import React from 'react'
import { Field, reduxForm, initialize as initializeForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { withTranslation, WithTranslation } from 'react-i18next'

import Panel from 'components/Panel'
import InputField from 'components/forms/InputField'
import DropzoneField from 'components/forms/DropzoneField'

interface Props {
  additionalInfo: any
  employeeName: string
  isOpen: boolean
  contract: Contract
  dispatch(action: any): void
  handleSubmit(action: any): any
  onUpdate(values: any): void
}

interface States {
  modal: boolean
}

class FormationlInfoColumn extends React.Component<
  Props & WithTranslation,
  States
> {
  state = { modal: false }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  onUpdateInfo = () => {
    const { additionalInfo } = this.props

    this.props.dispatch(
      initializeForm('FormationInfoForm', additionalInfo || {})
    )
    this.setState({
      modal: true,
    })
  }

  onSubmit = (values: any) => {
    this.props.onUpdate(values)
    this.setState({ modal: false })
  }

  render() {
    const { additionalInfo, employeeName, handleSubmit, t } = this.props
    const { modal } = this.state
    const isPending = !additionalInfo || !Object.values(additionalInfo).length

    return (
      <div>
        <div className="mb-3">
          {isPending ? (
            <>
              <img src="/img/error.jpg" alt="cart" width="50" />
              <span className="label label-danger">{t('cart.pending')}</span>
            </>
          ) : (
            <>
              <img src="/img/ok.jpg" alt="cart" width="50" />
              <span className="label label-success">{t('cart.completed')}</span>
            </>
          )}
        </div>

        <div>
          <button
            type="button"
            className="btn btn-default btn-icon"
            onClick={this.onUpdateInfo}
          >
            {t('buttons.update')}
            <i className="entypo-pencil" />{' '}
          </button>
        </div>

        {modal && (
          <Modal
            className="cart-edit-modal"
            isOpen={true}
            toggle={this.toggleModal}
          >
            <ModalHeader toggle={this.toggleModal} />
            <ModalBody>
              <div className="heading4 mb-2">{employeeName}</div>

              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Panel>
                  <div className="form-group row">
                    <div className="col-md-4">
                      <label className="required">
                        {t('cart.center_name')}:
                      </label>
                    </div>
                    <div className="col-md-8">
                      <Field
                        type="text"
                        name="centerName"
                        component={InputField}
                        validate={required()}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-4">
                      <label className="required">{t('cart.course')}:</label>
                    </div>
                    <div className="col-md-8">
                      <Field
                        type="text"
                        name="course"
                        component={InputField}
                        validate={required()}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-4">
                      <label className="required">
                        {t('cart.phone_contact')}:
                      </label>
                    </div>
                    <div className="col-md-8">
                      <Field
                        type="text"
                        name="phoneContact"
                        component={InputField}
                        validate={required()}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <h5>{t('cart.attach_invoice')}</h5>
                    <Field
                      type="text"
                      name="invoices"
                      component={DropzoneField}
                      validate={required()}
                    />
                  </div>

                  <div className="form-group row">
                    <div className="col-md-4">
                      <label className="required">
                        {t('cart.payment_frequency')}:
                      </label>
                    </div>
                    <div className="col-md-8">

                      <div>
                        <label className="mr-4"><Field validate={required()} name="paymentFreq" component={InputField} type="radio" value="yearly"/> {t('products.yearly')} </label>
                        <label><Field validate={required()} name="paymentFreq" component={InputField} type="radio" value="monthly"/> {t('products.monthly')} </label>
                      </div>

                    </div>
                  </div>



                </Panel>

                <div className="text-right p-2">
                  <Button type="submit" color="success">
                    {t('buttons.update')}
                  </Button>
                  <Button
                    type="button"
                    className="ml-3"
                    color="default"
                    onClick={this.toggleModal}
                  >
                    {t('buttons.cancel')}
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}

export default withTranslation()(
  reduxForm({
    form: 'FormationInfoForm',
  })(FormationlInfoColumn)
)
