import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/number'
import { getSimulation } from 'store/simulator/reducer'
import './BagInfo.scss'

type Props = {
  showEmployBag?: boolean
  showTopBag?: boolean
  benefit?: Benefit
}

const BagInfo: React.FC<Props> = props => {
  const { t } = useTranslation()
  const { benefit } = props
  let { showEmployBag, showTopBag } = props;
  const simulation: Simulation = useSelector(getSimulation)

  const {
    flexibleBag,
    topBag,
    userBag,
    availableUserBag,
    companyBag,
    totalPrice,
    available,
    availableTop,
    contributionById,
    pricesById,
    fixedSalary
  } = simulation

  let contributionCompany = 0
  let productPrice = 0
  let showUserBag = !!userBag

  if (benefit) {
    showUserBag = showUserBag && benefit.userBag
    productPrice = pricesById[benefit['id']]
    contributionCompany = contributionById[benefit['id']]
  }

  if (benefit && benefit.isTaxAdvantage) {
    showEmployBag = true;
    showTopBag = false;
  } else if (benefit && !benefit.isTaxAdvantage) {
    showEmployBag = false;
    showTopBag = true;
  }

  let contributionSpent = 0
  if (contributionCompany) {
    contributionSpent = Math.round((Math.min(productPrice, contributionCompany) / contributionCompany) * 100)
  }

  let companyBagSpent

  if (companyBag && !companyBag.endsWith('%')) {
    const companyBagPrice = parseInt(companyBag, 10)
    const discount = Math.min(companyBagPrice, totalPrice)
    companyBagSpent = companyBagPrice > 0 ? Math.round((discount / companyBagPrice) * 100) : 0
  } else {
    companyBagSpent = 0
  }

  const flexBagSpent = flexibleBag
      ? Math.round(((flexibleBag - available) / flexibleBag) * 100)
      : 0

  const topBagSpent = topBag
      ? Math.round(((topBag - availableTop) / topBag) * 100)
      : 0

  const userBagSpent = userBag
      ? Math.round(((userBag - availableUserBag) / userBag) * 100)
      : 0


  if (!fixedSalary || fixedSalary < flexibleBag) {
    return (
      <div className="alert alert-warning" role="alert">
        {t('calculator.not_available')} -&nbsp;
        <Link to="/profile?tab=employee">{t('calculator.edit_profile')}</Link>
      </div>
    )
  }

  return (
    <div className="row mt-3">
      {showEmployBag && (
        <div className="col-md-6 pb-4">
          <div className="grey-bar">{t('calculator.employee_bag')}</div>
          <div className="bag-box">
            <div>
              <img src="/img/employee-bag.jpg" width="50" alt="employee bag" />
            </div>
            <div className="flex-grow-1 ml-3">
              <label>{t('calculator.available')}:</label>
              <span className="text-black ml-2">
                {formatCurrency(available)}
              </span>
              <div className="progress progress-striped active mb-0">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  style={{ width: `${flexBagSpent}%` }}
                >
                  <span className="sr-only">40% Complete (success)</span>
                </div>
              </div>
              <small>
                {t('calculator.spent')} {flexBagSpent}%
              </small>
            </div>
          </div>
        </div>
      )}
      {showTopBag && topBag ? (
        <div className="col-md-6 pb-4">
          <div className="grey-bar">{t('calculator.employee_top_bag')}</div>
          <div className="bag-box">
            <div>
              <img src="/img/employee-bag.jpg" width="50" alt="top bag" />
            </div>
            <div className="flex-grow-1 ml-3">
              <label>{t('calculator.available')}:</label>
              <span className="text-black ml-2">
                {formatCurrency(availableTop)}
              </span>
              <div className="progress progress-striped active mb-0">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  style={{ width: `${topBagSpent}%` }}
                >
                  <span className="sr-only">40% Complete (success)</span>
                </div>
              </div>
              <small>
                {t('calculator.spent')} {topBagSpent}%
              </small>
            </div>
          </div>
        </div>
      ) : null}
      {((companyBag && parseInt(companyBag, 10) > 0)  || (contributionCompany) > 0) && (
        <div className="col-md-6">
          <div className="grey-bar">{t('calculator.company_bag')}</div>
          <div className="bag-box">
            <div>
              <img src="/img/company-bag.jpg" width="50" alt="company bag" />
            </div>
            <div className="flex-grow-1 ml-3">

              <label className='mb-0'>{t('calculator.company_bag')}:</label>
              <span className="text-black ml-2">
                {formatCurrency(companyBag)}
              </span>
              <div className="progress progress-striped active mb-0">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  style={{
                    width: `${companyBagSpent}%`,
                  }}
                >
                  <span className="sr-only">40% Complete (success)</span>
                </div>
              </div>
              <small>
                {t('calculator.spent')} {companyBagSpent}%
              </small>


              {benefit && contributionCompany ? <React.Fragment>
                <br />
                <label className='mb-0'>{t('calculator.contribution_company')}:</label>
                <span className="text-black ml-2">
                {formatCurrency(contributionCompany)}
              </span>
                <div className="progress progress-striped active mb-0">
                  <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      style={{
                        width: `${contributionSpent}%`,
                      }}
                  >
                    <span className="sr-only">40% Complete (success)</span>
                  </div>
                </div>
                <small>
                  {t('calculator.spent')} {contributionSpent}%
                </small>
              </React.Fragment> : null}


            </div>
          </div>
        </div>
      )}
      {showUserBag ? (
          <div className="col-md-6 pb-4">
            <div className="grey-bar">{t('calculator.employee_user_bag')}</div>
            <div className="bag-box">
              <div>
                <img src="/img/employee-bag.jpg" width="50" alt="top bag" />
              </div>
              <div className="flex-grow-1 ml-3">
                <label>{t('calculator.available')}:</label>
                <span className="text-black ml-2">
                {formatCurrency(availableUserBag)}
              </span>
                <div className="progress progress-striped active mb-0">
                  <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      style={{ width: `${userBagSpent}%` }}
                  >
                    <span className="sr-only">40% Complete (success)</span>
                  </div>
                </div>
                <small>
                  {t('calculator.spent')} {userBagSpent}%
                </small>
              </div>
            </div>
          </div>
      ) : null}
    </div>
  )
}

BagInfo.defaultProps = {
  showEmployBag: true,
  showTopBag: true
}

export default BagInfo
