import React, { FC } from "react"
import './MenuItem.scss'

interface Props {
  title: string,
  icon?: string
}


const MenuItem: FC<Props> = ({ title ,icon }) => {
    icon = icon || 'hand-pointer-o'
    const style = {
        backgroundImage: `url("/img/menu/${icon}.png")`
    }

    return (
          <fieldset style={style}>
              <legend >
                  <i className={`fa fa-${icon} menu-item-legend`}></i>
              </legend>
              <div className="menu-item-text">
                  {title}
              </div>
          </fieldset>

  )
}

export default MenuItem
