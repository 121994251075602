/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getTranslate } from 'helpers/translate'
import { formatCurrency } from 'utils/number'
import { removeItem } from 'store/cart/actions'
import './Header.scss'
import moment from 'moment'

type Props = {
  user: User
  cart: Contract[]
  calculation: Calculation
  simulation: Simulation
  logout(): void
  dispatch(action: any): void
}

const languages = [
  { code: 'es', name: 'Español' },
  { code: 'ca', name: 'Catalan' },
  { code: 'en', name: 'English' },
  { code: 'pt', name: 'Português' },
]

const Header: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()
  const { user, cart, calculation, simulation } = props
  const { company } = user
  const supportedLanuages = languages.filter(
    item => company.languages && company.languages.indexOf(item.code) >= 0
  )
  const isExentProducts = user && user.products.find(product => product.PL === 'Exent')

  const disableLanguage = process.env.REACT_APP_DISABLE_LANGUAGE === '1'

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
    moment.locale(i18n.language)
  }

  const handleRemoveItem = (e, item) => {
    e.preventDefault()
    props.dispatch(removeItem(item.id))
  }

  return (
    <div className="header d-flex justify-content-between">
      <div className="d-flex">
        <div className="mr-5">
          <ul className="user-info">
            <li className="profile-info dropdown">
              <a className="dropdown-toggle text-capitalize">
                <img
                  src={
                    user.picture
                      ? `/api/storage/images/${user.picture}`
                      : '/img/profile.jpg'
                  }
                  alt="avatar"
                  className="img-circle"
                  width="44"
                />
                {user.firstName && user.firstName.toLowerCase()}
              </a>
            </li>
          </ul>
        </div>

        {isExentProducts && (
          <div className="hidden-xs">
            <div className="d-flex">
              <div className="d-flex flex-column container-amounts">
                <div className="amount-highlight">
                  <strong>{formatCurrency(calculation.saving)}</strong>
                </div>
                <div>
                  <span>{t('HEADER.SAVED')}</span>
                </div>
              </div>

              <div className="d-flex flex-column container-amounts ml-4">
                <div className="amount-highlight">
                  <strong>{formatCurrency(calculation.pending)}</strong>
                </div>
                <div>
                  <span>{t('HEADER.PENDING_SAVING')}</span>
                </div>
              </div>

              <div>
                {!(simulation.available > 0) && (
                  <div className="d-flex flex-column container-amounts header-warning ml-4">
                    <div>
                      <strong>{t('HEADER.LIMIT')}</strong>
                    </div>
                    <div>
                      <strong>{t('HEADER.REACHED')}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="clearfix">
          <ul className="list-inline links-list pull-right">
            <li className="dropdown language-selector">
              <a
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-close-others="true"
              >
                {t('HEADER.HELP')} <i className="entypo-help" />
              </a>
              <ul className="dropdown-menu dropdown-menu-help pull-right">
                <li>
                  <a href="mailto:retribucionflexible@confide.es">
                    <i className="entypo-mail" /> retribucionflexible@confide.es
                  </a>
                </li>
              </ul>
            </li>

            {!disableLanguage && (

                <li className="dropdown language-selector">
                  {t('HEADER.LANGUAGE')}: &nbsp;
                  <a
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      data-close-others="true"
                  >
                    <img
                        src={`/assets/images/flags/flag-${i18n.language}.png`}
                        alt="flag"
                        width="16"
                        height="16"
                    />
                  </a>
                  <ul className="dropdown-menu pull-right">
                    {supportedLanuages.map(lang => (
                        <li
                            key={lang.code}
                            className={i18n.language === lang.code ? 'active' : ''}
                        >
                          <a
                              onClick={() => {
                                changeLanguage(lang.code)
                              }}
                          >
                            <img
                                src={`/assets/images/flags/flag-${lang.code}.png`}
                                alt="flag"
                                width="16"
                                height="16"
                            />
                            <span>{lang.name}</span>
                          </a>
                        </li>
                    ))}
                  </ul>
                </li>

            )}

            <li className="sep" />
            <li className="notifications cart dropdown">
              <a>
                <img src="/img/cart.jpg" alt="cart" width="50" />
                <span
                  className={`badge ${cart.length &&
                    'badge-success'} chat-notifications-badge`}
                >
                  {cart.length}
                </span>
              </a>
              {cart.length > 0 && (
                <ul className="dropdown-menu">
                  <li>
                    <ul className="dropdown-menu-list scroller">
                      {cart.map(item => {
                        return (
                          <li key={item.id}>
                            <div className="cart-item">
                              {item.benefit && item.benefit.logo && (
                                <span className="image">
                                  <img
                                    src={`/api/storage/images/${item.benefit.logo}`}
                                    width="44"
                                    alt="card"
                                  />
                                </span>
                              )}
                              <span>
                                {item.benefit &&
                                  getTranslate(
                                    item.benefit,
                                    'name',
                                    i18n.language
                                  )}
                              </span>
                              <span className="cost ml-auto">
                                {formatCurrency(item.finalPrice)}
                              </span>
                              <span
                                role="button"
                                className="ml-2"
                                title="Remove"
                                onClick={e => {
                                  handleRemoveItem(e, item)
                                }}
                              >
                                X
                              </span>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                  <li className="checkout-item text-right">
                    <Link to="/cart" className="p-0">
                      {t('HEADER.CHECKOUT')}
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="sep" />

            <li>
              <a className="cp" title="Log out" onClick={props.logout}>
                <img src="/img/logout.jpg" alt="logout" width="50" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
